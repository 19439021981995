import React, { useState } from 'react';
import Table from 'react-bootstrap/Table';
import { countries, countryTollTypes, countryIsUnit } from './country-card';
import { calcPrice, getDays } from './pricing';
import { paymentRadios } from './basket-cards';

function countryExists(cc) {
    return countries.filter((ctr) => ctr[0] == cc).length > 0;
}

function format_date(ds) {
    if (ds === null) {
        return null;
    }
    var d = new Date(ds);
    var yyyy = d.getFullYear();
    var mm = d.getMonth() + 1;
    var dd = d.getDate();
    return dd + '.' + String.fromCharCode(160) + mm + '.' + String.fromCharCode(160) + yyyy;
}

function getSummaryData(item, lists) {
    // console.log(item.data);
    const cc = item.data.country;
    const country = countries.filter((ctr) => ctr[0] == cc)[0][1];
    const ttype = item.data.tollType;
    const ttypename = countryTollTypes[cc] ? countryTollTypes[cc].filter((tt) => tt[0] == ttype)[0][1] : "";
    const isUnit = countryIsUnit(cc);
    const vehicle = item.data.vehicle;
    const maybeCategories = isUnit ? lists.categories.filter((cat) => cat.id == vehicle) : false;
    const validity = item.data.validity;
    const maybeValidities = isUnit ? false : lists.validities.filter((val) => val.id == validity);
    const maybeCategory = isUnit && maybeCategories && maybeCategories.length > 0 ? maybeCategories[0].name : "";
    const maybeValidity = !isUnit && maybeValidities && maybeValidities.length > 0 ? maybeValidities[0].description : "";
    const catOrVal = isUnit ? maybeCategory : maybeValidity;
    const sumDateX = isUnit ? item.data.deliveryDate : item.data.validFromDate;
    const sumDate = (ttype == 'tunnel') ? "" : format_date(sumDateX);
    const maybeCount = parseInt(item.data.count);
    const count = maybeCount > 0 ? maybeCount : 1;
    const spz = item.data.spz ? item.data.spz : "";
    var spzs = ('' + item.data.spzs).match(/[a-z0-9]+/ig);
    if (spzs == null) {
	spzs = [];
    }
    var spzsCount = spzs ? spzs.length : 0;
    const SPZnum = spzsCount;
    const countForPrice = ttype == 'mass' ? SPZnum : count;
    const tunnel = item.data.tunnel;
    const credit = item.data.credit;
    // console.log(item.data);
    const days = ((cc == 'hr') || (cc == 'it') || (cc == 'fr') || (cc == 'no-dk-swe')) ? getDays(item.data.deliveryDate, item.data.returnDate) : 0;
    const { p_price, p_fee, p_rental, p_deposit, p_credit, p_nominal } = calcPrice(cc, ttype, vehicle, validity, countForPrice, tunnel, credit, lists, days);
    return {
	country: country,
	ttypename: ttypename,
	catOrVal: catOrVal,
	sumDate: sumDate,
	count: countForPrice,
	spz: spz,
	p_price: p_price,
	p_fee: p_fee
    }
}

function SimpleSummaryRow({item, lists}) {
    const { country, ttypename, catOrVal, sumDate, count, spz, p_price, p_fee } = getSummaryData(item, lists);
    return (
	<tr>
	  <td>{country}</td>
	  <td>{ttypename}</td>
	  <td>{catOrVal}</td>
	  <td>{sumDate}</td>
	  <td>{count}</td>
	  <td>{spz}</td>
	  <td>{p_price}</td>
	</tr>
    );
}

export function SimpleSummary({items,lists}) {
    const itemsFiltered = items.filter((item) => countryExists(item.data.country));
    return (
	<Table size="sm">
	  <thead>
	    <tr>
	      <th>Stát</th>
	      <th>Typ</th>
	      <th>Platnost / Kategorie</th>
	      <th>Platnost od / Doručení</th>
	      <th>Počet</th>
	      <th>RZ vozidla</th>
	      <th>Cena</th>
	    </tr>
	  </thead>
	  <tbody>
	    {itemsFiltered.map((item) => <SimpleSummaryRow key={item.id} item={item} lists={lists} />)}
	  </tbody>
	</Table>
    );
}

export function SimpleSummaryTotalPrice({items, lists, deliveryMethod, paymentMethod, eDMethods}) {
    const itemsFiltered = items.filter((item) => countryExists(item.data.country));
    const itemsSummaries = itemsFiltered.map((item) => getSummaryData(item, lists));
    var totalPrice = itemsSummaries.reduce((acc, item) => acc+item.p_price, 0);
    switch (deliveryMethod) {
    case 1: // personal
	if (eDMethods[1]) {
	    totalPrice += 60;
	}
	break;
    case 2: // post
    case 4: // zasilkovna
	if (eDMethods[2]) {
	    totalPrice += 90; //změna 80 -> 90
	}
	break;
    case 3: // courier
	if (eDMethods[3]) {
	    totalPrice += 140; // změna 130 -> 140
	}
	break;
    default:
	// error
	break;
    }
    switch (paymentMethod) {
    case 1:
    case 2:
    case 3:
	break;
    case 4: // pay at delivery
	totalPrice += 60;
	break;
    }
    return (
        <h5 className="text-right mt-1">Celková cena k platbě vč. DPH: {totalPrice},-</h5>
    );
}

export function SimpleSummaryBasket({ note, deliveryMethod, lists, paymentMethod, addresses, enabledDM }) {
    const hasNote = (''+note).length > 0;
    const noteSummary = hasNote ? <>
	  <h6 className="mb-0 d-inline-block">Poznámka&nbsp;&nbsp;</h6>
          <span>{note}</span>
	  </>
	  : "";
    const deliveryMethodArr = lists.deliveryMethods.filter((dm) => dm.number == deliveryMethod);
    var deliveryMethodString = deliveryMethodArr.length > 0 ? deliveryMethodArr[0].name : "-";
    if (deliveryMethod == 4) {
	deliveryMethodString = 'Zásilkovna';
    }
    const paymentMethodArr = paymentRadios.filter((pm) => pm.number == paymentMethod);
    const paymentMethodString = paymentMethodArr.length > 0 ? paymentMethodArr[0].label : "-";
    const addressesArr = addresses.filter((adr) => ('' + adr).length > 0);
    const addressesString = addressesArr.join(", ");
    const noDeliveryMethods = !(enabledDM[1] || enabledDM[2] || enabledDM[3] || enabledDM[4]);
    return (
	<>
	  { noDeliveryMethods ? <></> :
	      <>
		    <h6 className="mb-0 d-inline-block">Doprava&nbsp;&nbsp;</h6> 
			<span>{deliveryMethodString}</span> <br />
	      </>}
        <h6 className="mb-0 d-inline-block">Platba&nbsp;&nbsp;</h6>
        <span>{paymentMethodString}</span> <br />
        <h6 className="mb-0 d-inline-block ">Fakturační a kontaktní údaje&nbsp;&nbsp;</h6>
        <span>{addressesString}</span> <br />
        {noteSummary}
	</>
    );
}
