import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import { countries, CountryCard, PayOrContinue, countryIsUnit } from './country-card';
import { BasketCanSend, InvoiceDelivery, DeliverPay, Overpaid } from './basket-cards';
import { Summary } from './summary';
import { calcPrice, getProductCode, getDays } from './pricing';
import { SimpleSummary, SimpleSummaryTotalPrice, SimpleSummaryBasket } from './simple-summary';

export function format_iso_date(dt) {
	if (dt) {
		try {
			const is = dt.toISOString();
			const isa = is.split("T");
			const isd = isa[0];
			return isd;
		} catch (ex) {
		}
	}
	return '';
}

export function Basket() {

	const [lists, setLists] = useState({});
	const [isLoading, setIsLoading] = useState(true);
	const [fetchError, setFetchError] = useState(null);
	const [items, setItems] = useState([]);
	const [lastItemId, setLastItemId] = useState(0);
	// sem patří stavové proměné konktatních údajů
	const [company, setCompany] = useState(""); // O basic company
	const [IC, setIC] = useState(""); // O basic ic
	const [DIC, setDIC] = useState(""); // O basic dic
	const [street, setStreet] = useState(""); // O basic street
	const [city, setCity] = useState(""); // O basic city
	const [zip, setZip] = useState(""); // O basic psc
	const [fullName, setFullName] = useState(""); // O basic name
	const [phone, setPhone] = useState(""); // O basic phone
	const [mail, setMail] = useState("");  // O basic email
	const [note, setNote] = useState(""); // O notes
	// stavy pro jiné doručovací údaje
	const [fullName2, setFullName2] = useState(""); // O postal name
	const [phone2, setPhone2] = useState(""); // O postal phone
	const [company2, setCompany2] = useState(""); // O postal company
	const [street2, setStreet2] = useState(""); // O postal street
	const [city2, setCity2] = useState(""); // O postal city
	const [zip2, setZip2] = useState(""); // O postal psc
	const [deliveryMethod, setDeliveryMethod] = useState(null); // O
	const [paymentMethod, setPaymentMethod] = useState(null); // O
	const [branch, setBranch] = useState(undefined); // O destination
	const [account, setAccount] = useState(null); // O basic account
	const [overpay, setOverpay] = useState(null); // O overpay

	const [sendingOrder, setSendingOrder] = useState(false);
	const [finished, setFinished] = useState(false);

	const [checkRead, setCheckRead] = useState(false);
	const [checkAgree, setCheckAgree] = useState(false);

	const [firma, setFirma] = useState(false);

	const [zasilkovnaStr, setZasilkovnaStr] = useState('');
	const [zasilkovnaName, setZasilkovnaName] = useState('');

	function confirmRead(ev) {
		setCheckRead(ev.target.checked);
	}

	function confirmAgree(ev) {
		setCheckAgree(ev.target.checked);
	}

	/*
  console.log("---------------------Fakturační a kontaktní údaje----------------");
  console.log("Company in basket: " + company);
  console.log("IC in basket: " + IC);
  console.log("DIC in basket: " + DIC);
  console.log("Street in basket: " + street);
  console.log("City in basket: " + city);
  console.log("zip in basket: " + zip);
  console.log("name in basket: " + fullName);
  console.log("phone in basket: " + phone);
  console.log("mail in basket: " + mail);
  console.log("note in basket: " + note);
  console.log("-------------------------------------");
  console.log("name2 in basket: " + fullName2);
  console.log("phone2 in basket: " + phone2);
  console.log("company2 in basket: " + company2);
  console.log("Street2 in basket: " + street2);
  console.log("City2 in basket: " + city2);
	console.log("zip2 in basket: " + zip2);
	console.log("branch in basket: " + branch);
	console.log("account in basket: " + account);
	console.log("overpay in basket: " + overpay);
	console.log("deliveryMethod in basket: " + deliveryMethod);
	console.log("paymentMethod in basket: " + paymentMethod);
	/**/

	function itemsContainUnits() {
		return items.filter((i) => countryIsUnit(i.data.country)).length > 0;
	}

	function itemIsPhysical(item) {
		const cc = item.country;
		if (countryIsUnit(cc)) {
			return true;
		}
		const pc = item.productCode;
		// console.log('cc = ' + cc + ' pc = ' + pc);
		switch (pc) {
			case 'ost':
			case 'ch':
			case 'slo':
				return true;
		}
		return false;
	}

	function getDeliveryMethods() {
		// Check types of goods
		var physical_goods = false;
		var virtual_goods = false;
		for (var idx in items) {
			const item = items[idx];
			// console.log(item);
			if (itemIsPhysical(item.data)) {
				physical_goods = true;
			} else {
				virtual_goods = true;
			}
		}

		// Start without any delivery methods enabled
		var methods = { // Don't ask ...
			1: false, // Personal
			2: false, // Post
			3: false, // Courier
			4: false // Zasilkovna
		};

		// Enable only certain methods nad only if any physical goods are present
		if (physical_goods) {
			methods[2] = true;
			methods[3] = true;
			methods[4] = true;
			if (virtual_goods) {
				// Only remote
			} else {
				// Local pick-up as well
				methods[1] = true;
			}
		} else {
			// No delivery methods!
		}

		// Forcibly disable certain methods for special cases
		for (var idx in items) {
			const item = items[idx];
			// console.log(item);
			if (itemIsPhysical(item.data)) {
				var tomorrow = new Date();
				tomorrow.setDate(tomorrow.getDate() + 1);
				tomorrow.setHours(0, 0, 0, 0);
				const fourDaysAfterToday = new Date();
				fourDaysAfterToday.setDate(fourDaysAfterToday.getDate() + 4);
				fourDaysAfterToday.setHours(0, 0, 0, 0);
				if (countryIsUnit(item.data.country)) {
					// Units
					if (item.data.deliveryDate < tomorrow) {
						// Courier starts tomorrow
						methods[3] = false;
					}
					if (item.data.deliveryDate < fourDaysAfterToday) {
						// Post only 4 days from today, Zasilkovna the same
						methods[2] = false;
						methods[4] = false;
					}
				} else {
					// Vignettes
					if ((item.data.validity != 1) && (item.data.validFromDate < tomorrow)) {
						methods[3] = false;
					}
					if ((item.data.validity != 1) && (item.data.validFromDate < fourDaysAfterToday)) {
						methods[2] = false;
						methods[4] = false;
					}
				}
			}
		}

		// Re-enable personal if none enabled and there is something
		// physical (do not check Zasilkovna - like post)
		if (!(methods[1] || methods[2] || methods[3])) {
			if (physical_goods) {
				methods[1] = true;
			}
		}
		return methods;
	}

	function isItemEToll(item) {
		switch (item.productCode) {
			case 'oste':
			case 'cze':
			case 'czh':
			case 'sk':
			case 'hu':
			case 'ostet':
			case 'slon':
				return true;
		}
		return false;
	}

	function getTotalPrice(dMethod, pMethod, eDMethods) {
		// TODO: probably fix
		var price = 0;
		for (var idx in items) {
			const item = items[idx];
			const data = item.data;
			const cc = data.country;
			// console.log(countries);
			const country = cc;
			const tolltype = data.tollType;
			const vehicle = data.vehicle;
			const validity = data.validity;
			const maybeCount = parseInt(item.data.count);
			const count = maybeCount > 0 ? maybeCount : 1;
			const tunnel = item.data.tunnel;
			const { p_price, p_fee, p_rental, p_deposit, p_credit, p_nominal } =
				calcPrice(country, tolltype, vehicle, validity, count, null, null, lists);
			price += p_price;
		}
		switch (dMethod) {
			case 1: // personal
				if (eDMethods[1]) {
					price += 60;
				}
				break;
			case 2: // post
			case 4: // Zasilkovna
				if (eDMethods[2]) {
					price += 90; //změna 80 -> 90
				}
				break;
			case 3: // courier
				if (eDMethods[3]) {
					price += 140; // změna 130 -> 140 
				}
				break;
			default:
				// error
				break;
		}
		switch (pMethod) {
			case 1:
			case 2:
			case 3:
				break;
			case 4: // pay at delivery
				price += 60;
				break;
		}
		return price;
	}

	function getPaymentMethods(deliveryMethods, totalPrice) {
		var methods = {
			1: deliveryMethod === 1, // Cash - set initally to true only for personal pickup
			2: true, // Transfer
			3: true, // Card
			4: !(deliveryMethod === 1) // Pay at delivery for postal delivery or courier
		}
		const fourDaysAfterToday = new Date();
		fourDaysAfterToday.setDate(fourDaysAfterToday.getDate() + 4);
		fourDaysAfterToday.setHours(0, 0, 0, 0);
		for (var idx in items) {
			const item = items[idx];
			if (itemIsPhysical(item.data)) {
				// Physical
				if (countryIsUnit(item.data.country)) {
					// Unit, transfers only if delivery is at least 4th day from now
					const deliveryOn = item.data.deliveryDate;
					// console.log('deliveryOn = ' + deliveryOn);
					if (deliveryOn < fourDaysAfterToday) {
						// console.log("Too soon!");
						methods[2] = false;
					}
				} else {
					// Vignette, transfers only if validity is at least 4th day from now
					const validFrom = item.data.validFromDate;
					const validity = item.data.validity;
					const cc = item.data.country;
					// console.log('validity = ' + validity);
					if ((validFrom < fourDaysAfterToday) && (!((validity === 1) || (validity === 6) || (validity === 7) || (validity === null) || (cc === 'ch')))) {
						methods[2] = false;
					}
				}
			} else {
				// Virtual
				methods[1] = false;
				methods[4] = false;
				if (isItemEToll(item.data)) {
					// E-toll, transfers only if validity is at least 4th day from today
					if ((item.data.tollType != 'tunnel') &&  // Not for tunnels
						(item.data.validity != 1)) // Not for yearly 
					{
						const validFrom = item.data.validFromDate;
						if (validFrom < fourDaysAfterToday) {
							methods[2] = false;
						}
					}
				}
			}
		}
		// Now check for total payment and if it is >270k, disable cash
		if (totalPrice > 270000) {
			methods[1] = false;
		}
		return methods;
	}

	function getValidities(productCode, vehicle, tunnel) {
		// console.log('getValiditites(' + productCode + ', ' + vehicle + ', ' + tunnel + ')');
		const countryValidities = lists.countryValidities;
		//this first if is not very systematic, but validities should be matched to productCodes, not countries
		if (productCode === "che") {
			return countryValidities['ch'];
		}
		if (countryValidities[productCode]) {
			// Simple one
			if (productCode === "gv") {
				return [...countryValidities[productCode]].reverse();
			} else {
				return countryValidities[productCode];
			}
		}
		const countryTypeValidities = lists.countryTypeValidities;
		if (countryTypeValidities[productCode]) {
			// Based on vehicle type
			const thisCountryValidities = countryTypeValidities[productCode];
			// console.log('thisCountryValidities = ' + JSON.stringify(thisCountryValidities));
			const thisVehicleValidities = thisCountryValidities
				.filter((v) => v[0] == vehicle)
				.map((v) => [v[1], v[2]]);
			// console.log('thisVehicleValidities = ' + JSON.stringify(thisVehicleValidities));
			return thisVehicleValidities;
		}
		const countryTunnelValidities = lists.countryTunnelValidities;
		if (countryTunnelValidities[productCode]) {
			const thisCountryValidities = countryTunnelValidities[productCode];
			const thisTunnelValidities = thisCountryValidities
				.filter((v) => v[0] == tunnel)
				.map((v) => [v[1], v[2]]);
			// console.log('thisTunnelValidities = ' + JSON.stringify(thisTunnelValidities));
			return thisTunnelValidities;
		}
		return [[0, "-"]];
	}

	function loadLists() {
		const uriprefix = process.env.NODE_ENV === 'development' ? 'https://testeshop.mtx.cz' : '';
		fetch(uriprefix + '/api/lists')
			.then(response => response.json())
			.then(function (data) {
				setLists(data);
				setIsLoading(false);
			})
			.catch(function (error) {
				setFetchError(error);
				setIsLoading(false);
			});
	}

	useEffect(loadLists, []);

	function removeItem(itemId) {
		//console.log(items);
		// console.log('Removing: ' + itemId);
		const newItems = items.filter((item) => item.id !== itemId);
		setItems(newItems);
		// console.log(newItems);
	}

	function addItem(preselected = false) {
		// console.log(lists);
		const newItemId = lastItemId + 1;
		setLastItemId(newItemId);
		// console.log(newItemId);
		// console.log(preselected);
		const data = preselected === 'gv' ?
			{
				country: preselected,
				validity: getValidities("gv", 0)[0][0],
				vehicle: 0,
				productCode: "gv",
				tollType: null,
				tunnel: null,
				validFromDate: null,
				deliveryDate: null,
				regCountry: "CZ",
				count: 1
			}
			:
			preselected === 'cz' ?
				{
					country: 'cz',
					validity: getValidities("cze", 0)[0][0],
					vehicle: 0,
					productCode: "cze",
					tollType: "etoll",
					tunnel: null,
					validFromDate: null,
					deliveryDate: null,
					regCountry: "CZ",
					count: 1
				}
				:
				{};
		//console.log(data);
		const newItemRecord = {
			data: data,
			id: newItemId
		};
		const newItems = items.concat([newItemRecord]);
		setItems(newItems);
	}

	function updateItems() {
		setItems(items.map((e) => e));
	}

	if (isLoading) {
		return <p>Loading...</p>;
	}

	if ((!isLoading) && (Object.keys(items).length === 0)) {
		const locHash = window.location.hash;
		var preselected = false;
		if (locHash.length > 1) {
			const hashValue = locHash.substring(1);
			if (hashValue === "voucher") {
				preselected = "gv";
			}
			if (hashValue === "ceskarepublika") {
				preselected = "cz";
			}
		}
		addItem(preselected);
	}

	// console.log(items);

	const enabledDeliveryMethods = getDeliveryMethods();
	const totalPrice = getTotalPrice(deliveryMethod, paymentMethod, enabledDeliveryMethods);
	const enabledPaymentMethods = getPaymentMethods(enabledDeliveryMethods, totalPrice);

	if (!enabledPaymentMethods[paymentMethod]) {
		// Card is universal fallback
		setPaymentMethod(3);
	}

	function safeParseInt(s) {
		try {
			return parseInt(s);
		} catch (ex) {
			return 0;
		}
	}

	function placeOrder() {
		if (!sendingOrder) {
			setSendingOrder(true);
			// console.log("Sending JSON");
			var order = {};
			order['delivery'] = {};
			const dMethod = safeParseInt(deliveryMethod);
			const realDMethod = dMethod == 4 ? 2 : dMethod;
			order['delivery']['method'] = '' + realDMethod;
			order['delivery']['destination'] = branch;
			order['payment'] = {};
			order['payment']['method'] = '' + safeParseInt(paymentMethod);
			order['basic'] = {};
			order['basic']['name'] = fullName;
			order['basic']['company'] = company;
			order['basic']['street'] = street;
			order['basic']['city'] = city;
			order['basic']['psc'] = zip;
			order['basic']['phone'] = phone;
			order['basic']['ic'] = IC;
			order['basic']['dic'] = DIC;
			order['basic']['account'] = account;
			order['basic']['email'] = mail;
			order['postal'] = {};
			order['postal']['name'] = fullName2;
			order['postal']['company'] = company2;
			order['postal']['street'] = street2;
			order['postal']['city'] = city2;
			order['postal']['psc'] = zip2;
			order['postal']['phone'] = phone2;
			const zasilkovna = dMethod == 4 ? zasilkovnaStr : "";
			order['notes'] = zasilkovna + note;
			order['overpay'] = overpay;
			order['items'] = {};
			var jsonIdx = 1;
			for (var iidx in items) {
				const item = items[iidx];
				order['items'][jsonIdx] = {};
				const country = item.data.country;
				const tollType = item.data.tollType;
				const days = getDays(item.data.deliveryDate, item.data.returnDate) + 1;
				const rental_price = item.data.country === 'hr' ? days * 60 : 0;
				//console.log(days);
				//console.log(item.data);
				const productCode = getProductCode(country, tollType);
				order['items'][jsonIdx]['sel-country'] = productCode;
				order['items'][jsonIdx]['sel-typeset'] = item.data.vehicle;
				order['items'][jsonIdx]['sel-regcountry'] = item.data.regCountry;
				order['items'][jsonIdx]['in-spz'] = item.data.spz;
				order['items'][jsonIdx]['sel-validity'] = safeParseInt(item.data.validity);
				order['items'][jsonIdx]['date-validfrom'] = format_iso_date(item.data.validFromDate);
				order['items'][jsonIdx]['in-spzs'] = item.data.spzs;
				order['items'][jsonIdx]['date-datefrom'] = format_iso_date(item.data.deliveryDate);
				order['items'][jsonIdx]['date-dateto'] = format_iso_date(item.data.returnDate);
				order['items'][jsonIdx]['sel-return'] = '' + safeParseInt(item.data.returnMethod);
				order['items'][jsonIdx]['in-credit'] = '' + safeParseInt(item.data.credit);
				order['items'][jsonIdx]['in-count'] = '' + safeParseInt(item.data.count);
				order['items'][jsonIdx]['sel-tunnel'] = item.data.tunnel;
				order['items'][jsonIdx]['sel-ways'] = safeParseInt(item.data.validity); // WTF!?!?!?
				order['items'][jsonIdx]['rental-price'] = rental_price;
				order['items'][jsonIdx]['inscription'] = item.data.inscription;
				jsonIdx += 1;
			}
			order['campaign'] = window.location.hash.substring(1) === "voucher" ? 1 : 0;
			const jsonData = JSON.stringify(order);
			//console.log(jsonData);
			const uriprefix = process.env.NODE_ENV === 'development' ? 'https://testeshop.mtx.cz' : '';
			fetch(uriprefix + '/api/order',
				{
					method: "POST",
					body: jsonData
				})
				.then(response => response.json())
				.then(function (resp) {
					// console.log(resp);
					setFinished(true);
				})
				.catch(function (error) {
					console.log(error);
					setFinished(true);
				});
		}
	}

	if (finished) {
		return (
			<>
				<p>Vaše objednávka byla úspěšně odeslána, děkujeme.</p>
				<p>Na emailovou adresu uvedenou v objednávce Vám zašleme podrobnější informace.</p>
				<p>Email s informacemi je zasílán automaticky. <b>V případě nedoručení prosíme zkontrolujte také SPAM.</b></p>
				<p>Přejeme šťastnou cestu s produkty MTX</p>
			</>
		);
	}
	if (sendingOrder) {
		return <p>Odesílám ...</p>;
	}

	const itemsWithUnits = itemsContainUnits();
	//console.log(items);

	return (
		<>
			{items.map((item) => <CountryCard key={item.id} holidays={lists.holidays} itemId={item.id} data={item.data} removeItem={removeItem} updateItems={updateItems} lists={lists} getValidities={getValidities} initCountry={item.data.country} />)}

			<PayOrContinue addItem={addItem} />
			<DeliverPay enabledDeliveryMethods={enabledDeliveryMethods} deliveryMethod={deliveryMethod}
				setDeliveryMethod={setDeliveryMethod} enabledPaymentMethods={enabledPaymentMethods}
				paymentMethod={paymentMethod} setPaymentMethod={setPaymentMethod}
				branches={lists.liveBranches} branch={branch} setBranch={setBranch}
				setZasilkovnaStr={setZasilkovnaStr}
				zasilkovnaName={zasilkovnaName} setZasilkovnaName={setZasilkovnaName} />
			{itemsWithUnits ? <Overpaid account={account} setAccount={setAccount} setOverpay={setOverpay} /> : ""}
			<InvoiceDelivery
				firma={firma}
				setFirma={setFirma}
				setCompany={setCompany} company={company}
				setIC={setIC} ic={IC}
				setDIC={setDIC} dic={DIC}
				setStreet={setStreet} street={street}
				setCity={setCity} city={city}
				setZip={setZip} zip={zip}
				setFullName={setFullName} fullName={fullName}
				setPhone={setPhone} phone={phone}
				setMail={setMail} mail={mail}
				onChange={setNote} note={note}
				setFullName2={setFullName2} fullName2={fullName2}
				onChangePhone={setPhone2} phone2={phone2}
				setCompany2={setCompany2} company2={company2}
				setStreet2={setStreet2} street2={street2}
				setCity2={setCity2} city2={city2}
				setZip2={setZip2} zip2={zip2}
			/>
			<Card body className="mb-2">
				<h5>Shrnutí objednávky</h5>
				{ /* items.map((item) => <Summary key={item.id} itemId={item.id} data={item.data} lists={lists} />) */}
				<SimpleSummary items={items} lists={lists} />
				{/* {items.map((item) => <Summary key={item.id} itemId={item.id} data={item.data} />)} */}

				<hr />  {/* tato linka je důležitá*/}

				<SimpleSummaryBasket note={note} deliveryMethod={deliveryMethod} lists={lists} paymentMethod={paymentMethod} addresses={[
					fullName, company, IC, DIC, street, city, zip, phone, mail, fullName2, company2, street2, city2, zip2, phone2
				]} enabledDM={enabledDeliveryMethods} />

				<SimpleSummaryTotalPrice items={items} lists={lists} deliveryMethod={deliveryMethod} paymentMethod={paymentMethod} eDMethods={enabledDeliveryMethods} />

				<hr />
				<Form.Group controlId="agree1">
					<Form.Check isValid={checkRead} isInvalid={!checkRead} onChange={confirmRead} className="small" type="checkbox"
						label={<p>
							Potvrzuji přečtení&nbsp;
							<a className='specialTermsAndConditions' target="_blank" href="https://www.mtx.cz/obchodni-podminky-pro-prodej-elektronickych-dalnicnich-znamek/">Obchodních podmínek</a>	pro mnou objednané produkty a Informace o zpracování osobních údajů pro klienty e-shopu a souhlasím s nimi.
						</p>} />
				</Form.Group>
				<Form.Group controlId="agree2">
					<Form.Check isValid={checkAgree} isInvalid={!checkAgree} onChange={confirmAgree} className="small" type="checkbox" label="Beru na vědomí, že po provedení registrace e-známky v mýtném systému není možné e-známku stornovat ani změnit termín její platnosti (s výjimkou situací, kdy to umožňuje provozovatel systému e-známky). Elektronické dálniční známky jsou poplatkem, který není možné vrátit, nelze na ně uplatnit práva a povinnosti vyplývající z Občanského zákoníku ani ze Zákona o ochraně spotřebitele." />
				</Form.Group>
				<BasketCanSend placeOrder={placeOrder} checkRead={checkRead} checkAgree={checkAgree} items={items}
					fullName={fullName} phone={phone} mail={mail} street={street} city={city} zip={zip}
					firma={firma} company={company} ic={IC} dic={DIC}
					itemsWithUnits={itemsWithUnits} account={account}
					enabledDM={enabledDeliveryMethods} enabledPM={enabledPaymentMethods}
					deliveryMethod={deliveryMethod} paymentMethod={paymentMethod}
					zasilkovnaStr={zasilkovnaStr}
				/>

			</Card>
		</>
	);
}
