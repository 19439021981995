import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function hr_extra_days(daysDuration) {
    const days = daysDuration + 1; // Include starting and ending day, not date difference
    return days > 9 ? days - 9 : 0;
}

function hr_extra_weeks(days) {
    const ed = hr_extra_days(days);
    // console.log('extra days = ' + ed);
    return Math.floor((ed + 6) / 7);
}

function tp_extra_days(days) {
    return days < 21 ? 0 : days - 21;
}

export function calcPrice(country, tolltype, vehicle, validity, count, tunnel, credit, lists, days) {
    var result = {
        p_price: 0 * count,
        p_fee: 0,
        p_rental: 0,
        p_deposit: 0,
        p_credit: 0,
        p_nominal: 0
    };
    const prices = lists.prices;
    const productCode = getProductCode(country, tolltype); //this is czh in case of mass
    //if (tolltype === null) {
    //	throw "error";
    //  }
    //console.log('country: ' + country);
    //console.log('tolltype: ' + tolltype);
    //console.log('productCode: ' + productCode);
    //console.log('validity: ' + validity);
    //console.log('vehicle: ' + vehicle);
    const category = vehicle;
    // console.log(prices);
    switch (productCode) {
        case 'hr':
            result.p_credit = count * credit;
            result.p_deposit = 1200 * count;
            const ew = hr_extra_weeks(days);
            // console.log('extra weeks = ' + ew);
            // const single_rent = 360+ew*259;
            result.p_rental = 0; // count*single_rent;
            result.p_price = (count * credit) + (count * 1200);
            // + (60 * count * (days + 1)); // + count*single_rent;
            break;
        case 'it':
        case 'fr':
        case 'no-dk-swe':
            result.p_credit = credit;
            result.p_deposit = 1100;
            const ed = tp_extra_days(days);
            const rent = 126 + 196 + ed * 10;
            result.p_rental = rent;
            result.p_price = credit + 1100; // + rent; // WTF???
            break;
        case 'ostet':
            // console.log('ostet: ' + validity + ' ' + category + ' ' + tunnel);
            for (var idx in prices) {
                const rec = prices[idx];
                const rcountry = rec.country;
                if (rcountry === productCode) {
                    // console.log(rec);
                    const rcategory = rec.category;
                    const rvalidity = rec.validity;
                    const rprice = rec.price;
                    const rtunnel = rec.tunnel;
                    if ((rcategory === null) || (category == rcategory)) {
                        if ((rvalidity == null) || (validity == rvalidity)) {
                            // console.log('validity matches');
                            if ((rtunnel === null) || (tunnel == rtunnel)) {
                                // console.log('tunnel matches');
                                // price = rprice;
                                result.p_price = rprice * count;
                            }
                        }
                    }
                }
            }
            break;
        default:
            // console.log('going through prices');
            for (var idx in prices) {
                const rec = prices[idx];
                const rcountry = rec.country;
                //console.log(rec);
                if (rcountry === productCode) {
                    //console.log(rec);
                    const rcategory = rec.category;
                    const rvalidity = rec.validity;
                    const rprice = rec.price;
                    const rfee = rec.fee;
                    /*
                    console.log(prices);
                    console.log(rec);
                    console.log(rec.fee);
                    */
                    //console.log((rcategory === null) || (category == rcategory))
                    if ((rcategory === null) || (category == rcategory)) {
                        //console.log('category matches');
                        if ((rvalidity === null) || (validity == rvalidity)) {
                            // console.log('validity matches');
                            result.p_price = rprice * count;
                            result.p_fee = rfee * count;
                            result.p_nominal = (rprice - rfee) * count;
                        }
                    }
                }
            }
            // special check for czv mass sale
            if (productCode === 'czv') {
                if (validity === 8) {
                    if (count >= 50) {
                        result.p_fee = 45 * count;
                        result.p_price = 1545 * count;
                    }
                }
            }
            break;
    }
    // console.log(result);
    return result;
}

function TellPrice({ country, tolltype, vehicle, validity, count, tunnel, credit, lists, days }) {
    return calcPrice(country, tolltype, vehicle, validity, count, tunnel, credit, lists, days);
}

export function SinglePrice({ country, validity, count, vehicle, lists, tolltype, tunnel }) { // je u czv, ch, slo

    // console.log('SinglePrice: ' + country + ' count=' + count);
    const { p_price, p_fee, p_rental, p_deposit, p_credit, p_nominal } = calcPrice(country, tolltype, vehicle, validity, count, tunnel, null, lists);
    // console.log('===============');
    //console.log(p_price);
    if (country === 'ost' ||
        country === 'sk' ||
        country === 'hu' ||
        country === 'slon' ||
        (country === 'ch' && tolltype === 'etoll')) {
        return (
            <Row>
                <Col>
                    <h5 className="float-right">Cena: {p_price},-</h5>
                </Col>
            </Row>
        );
    } else if (country === 'cz' ||
        country === 'slo' ||
        country === 'gv' ||
        (country === "ch" && tolltype === 'vignette')) {
        return (
            <Col xs='12' sm='6' md='3'>
                {country === 'cz' ?   // rozdíl v třídě d-block
                    <div className="d-none d-md-block price-style">&nbsp;</div>
                    :
                    <div className="d-none d-sm-block price-style">&nbsp;</div>
                }
                <h5 className="text-right mb-0">Cena: {p_price},-</h5>
            </Col>
        );
    } else {
        return (
            <p>Cena neznámá.</p>
        );
    }

    /*
    switch (country) {
        case 'ost':
        case 'sk':
        case 'hu':
        case 'slon':
            return (
                <Row>
                    <Col>
                        <h5 className="float-right">Cena: {p_price},-</h5>
                    </Col>
                </Row>
            )
        case 'cz':
        case 'ch':
        case 'slo':
        case 'gv':
            return (
                <Col xs='12' sm='6' md='3'>
                    {country === 'cz' ?   // rozdíl v třídě d-block
                        <div className="d-none d-md-block price-style">&nbsp;</div>
                        :
                        <div className="d-none d-sm-block price-style">&nbsp;</div>
                    }
                    <h5 className="text-right mb-0">Cena: {p_price},-</h5>
                </Col>
            );
        default:
            return (
                <p>Cena neznámá.</p>
            );
    }
    */
}

export function getDays(startDate, endDate) {
    try {
        const time_difference = endDate.getTime() - startDate.getTime();
        return Math.round(time_difference / (1000 * 60 * 60 * 24));
    } catch (ex) {
        return 0;
    }
}

export function CompoundPrice({ country, tolltype, validity, count, credit, deliveryDate, returnDate, vehicle, SPZnum, lists }) { //je u cze, czh, hr, it, fr, no-dk-swe

    const days = getDays(deliveryDate, returnDate);
    const { p_price, p_fee, p_rental, p_deposit, p_credit, p_nominal } = calcPrice(country, tolltype, vehicle, validity, tolltype === 'mass' ? SPZnum : count, null, credit, lists, days);

    switch (country) {
        case 'cz':
            const poplatek = 60;
            const cena = 5;
            //we miss validity, spzNum for the counting logic, validity is here
            // console.log("Validity is: " + validity);
            switch (tolltype) {
                case 'etoll':
                    return (
                        <Row>
                            <Col>
                                <p className="float-right text-muted text-right mb-0">Cena e-známky: {p_nominal},-</p>
                                <br />
                                <p className="float-right text-muted text-right mb-0">Manipulační poplatek: {p_fee},-</p>
                                <br />
                                <h5 className="float-right mb-0">Cena: {p_price},-</h5>
                                <br />
                            </Col>
                        </Row>
                    );
                case 'mass':
                    // tady bude asi nějaký magič pro výpočet hromadného nákupu
                    return (
                        <div>
                            <div className="d-none d-sm-block mt-4">&nbsp;</div>
                            <p className="float-right text-muted text-right mb-0">Cena {SPZnum}ks e-známek: {p_nominal},- </p>
                            <br />
                            <p className="float-right text-muted text-right mb-0">Manipulační poplatek: {p_fee},-</p>
                            <br />
                            <h5 className="float-right mb-0">Cena: {p_price},-</h5>
                            <br />
                        </div>
                    );
                default:
                    return (
                        <p>Cena není k dispozici.</p>
                    );
            }
        case 'hr':
        case 'it':
        case 'fr':
        case 'no-dk-swe':
            //we miss deliveryDate, ReturnDate and vehicle for count logic, but states are here 
            //console.log("DeliveryDate is: " + deliveryDate);
            //  console.log("ReturnDate is: " + returnDate);
            //console.log("Vehicle category is: " + vehicle);

            const rental = count * 360;
            const deposit = count * 1100;
            const creditt = count * credit;
            //renaming made 21.4.
            const deposit_label = country === 'hr' ? "Zúčtovatelná kauce: " : "Vratná kauce: ";
            //const price_label = country === 'hr' ? "Požadovaná záloha k úhradě: " : "Cena: ";
            const rent_label = country === 'hr' ? "Předpokládaný pronájem" : "Pronájem";
            return (
                <Col xs='12' sm='6' md='3'>
                    <div className='text-muted text-right'>
                        {country == 'xxx' ? <>
                            Půjčovné: {p_rental},-
                            <br />
                        </> : ""}
                        {deposit_label} {p_deposit},-
                        <br />
                        Kredit: {p_credit},-
                        <br />
                        {country === 'hr' ?
                            <>
                                {rent_label}: {(days + 1) * 60 * count},-
                                <br />
                            </> : ""}
                        <h5 className="float-right text-dark">Cena: {p_price},-</h5>
                    </div>
                </Col>
            );
        default:
            return (
                <p>Cena není k dispozici.</p>
            );
    }
}

export function getProductCode(country, tolltype) {

    switch (country) {
       // case 'ch':
        case 'sk':
        case 'slo':
        case 'hu':
        case 'hr':
        case 'it':
        case 'fr':
        case 'no-dk-swe':
        case 'slon':
        case 'gv':
            return country;

        case 'cz':
            if (tolltype == 'etoll') {
                return "cze";
            } else if (tolltype == 'voucher') {
                return "czv";
            } else if (tolltype == 'mass') {
                return "czh";
            }
            return "chyba";

        case 'ost':
            if (tolltype == 'etoll') {
                return "oste";
            } else if (tolltype == 'vignette') {
                return "ost";
            } else if (tolltype == 'tunnel') {
                return "ostet";
            }
            return "chyba";

        case 'ch':
            if (tolltype === 'etoll') {
                return 'che';
            } else if (tolltype === 'vignette') {
                return 'ch';
            }
            return "chyba";
    }
}

