import React, { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { RenderCountryOptions } from './country-card';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import cs from 'date-fns/locale/cs';
import InputGroup from 'react-bootstrap/InputGroup';
import { FaCalendarAlt } from 'react-icons/fa';
import Button from 'react-bootstrap/Button';
import parseISO from 'date-fns/parseISO'
import { getProductCode } from './pricing';
import { format_iso_date } from './basket';
registerLocale('cs', cs);

export function formatCZDate(date) {
    if (typeof (date) == "string") {
        return date;
    }
    // console.log('formatCZDate('+date+')');
    const y = date.getFullYear();
    const m = date.getMonth() + 1;
    const d = date.getDate();
    // console.log('y='+y+',m='+m+',d='+d);
    return '' + d + '. ' + m + '. ' + y;
}

/*
export const countryVehicleTypes = {
    "hu": [
        ["9", "D1 do 3,5t – osobní max. 7 míst "],
        ["10", "D2 do 3,5t – nákladní nebo nad 7 míst "],
        ["8", "Motocykl"],
        ["11", "Přívěs (pouze pro D2) "],
    ],
    "sk": [
        ["0", "Osobní do 3,5t "],
        ["1", "Přípojný vozík "]
    ],
    "ost": [
        ["0", "Osobní do 3,5t "],
        ["8", "Motocykl"]
    ],
    "hr": [
        ["2", "Kategorie I – do 1,9m"],
        ["3", "Kategorie II – nad 1,9m nebo do 1,9m výšky s přívěsem "],

    ],
    "fr": [
        ["5", "Kategorie 1 – do 2m"],
        ["6", "Kategorie 2 – do 3m"],
        ["7", "Kategorie 5 – motocykl "]
    ],
    "slon": [
        ["13", "Kategorie 2A – vozidla do 3,5t a 1,3m výšky"],
        ["14", "Kategorie 2B – vozidla nad 3,5t a 1,3m výšky"],
        ["12", "Kategorie 1 – Motocykl"],
    ]
};
*/

// vrací dnešek 
export function getMinDate(notToday, notTomorrow) {
    var d = new Date();
    if (notToday) {
        d.setDate(d.getDate() + 1);
	if (notTomorrow) {
	    d.setDate(d.getDate() + 1);
	}
    }
    return d;
}

// Special function for TT Units
export function getMinDateTT() {
    var d = new Date();
    for (var i = 0 ; i < 14 ; i++) {
        d.setDate(d.getDate() + 1);
    }
    return d;
}

// vrací poslední den roku - 31.12.2021
function getMaxDate() {
    var maxDate = new Date();
    maxDate.setMonth(11);
    maxDate.setDate(31);
    return maxDate;
}

function RenderCountryOptions2({ options }) {
    // console.log("========================================");
    // console.log("objectkeys = " + Object.keys(options));
    return (
        <>
            {
                Object.keys(options).map(function (cntr) {
                    // console.log(cntr + ' => ' + options[cntr]);
                    return (
                        <option value={cntr} key={cntr}>
                            {options[cntr].name}
                        </option>
                    );
                })
            }
        </>
    );
}


export function VehicleTypeForm({ country, onChange, vehiclesTypes, vehicle, tollType = null }) {
    //console.log(country);
    //console.log(vehiclesTypes);

    //this is necessary, because in all previous cases typeSets[country] was enought, I don't want to refactor it right now
    const final_country = country === 'ch' && tollType === "etoll" ? 'che' : country;
   // const vehicles = countryVehicleTypes[country];
    const vehicles2 = vehiclesTypes ? vehiclesTypes[final_country] : [];
    //console.log(vehicles2);


    const changeVehicle = function (ev) {
        // console.log("Answer vehicle: " + ev.target.value);
        if (isFunction(onChange)) {
            onChange(ev.target.value);
        }
    };

    return (
        <>
            <Col xs='12' sm='6' md='3'>
                <Form.Group controlId="vehicle-type">
                    <Form.Label>Druh vozidla</Form.Label>
                    <Form.Control value={vehicle} size="sm" as="select" onChange={changeVehicle}>
                       {/**  <RenderCountryOptions options={vehicles} />
                        
                        <option>---------------</option> */}
                        <RenderCountryOptions options={vehicles2} sorting country={country} />
                        
                    </Form.Control>
                </Form.Group>
            </Col>
        </>
    );
}

function DatePickerCustom({ onChange, minDate, maxDate, holidays, weekends, value }) {
    const [startDate, setStartDate] = useState(value);

    // console.log('dpc value = ' + value);

    //const endDate = addDays(startDate, dny);
    // const startDateStr = formatCZDate(startDate);
    // const endDateStr = formatCZDate(endDate);

    const isWeekday = date => {
        if (weekends === true) {
            return true;
        }
        const day = date.getDay();
        return day !== 0 && day !== 6;
    };

    const MyInput = React.forwardRef(
        ({ value, onClick }, ref) => (
            <InputGroup onClick={onClick} ref={ref} >
                <Form.Control className='bg-white' readOnly size="sm" value={formatCZDate(value)} />
                <InputGroup.Append>
                    <InputGroup.Text><FaCalendarAlt /></InputGroup.Text>
                </InputGroup.Append>
            </InputGroup>
        ),
    );

    const changeDate = function (date) {
        setStartDate(date);
        if (isFunction(onChange)) {
            onChange(date);
        }
    }

    return (
        <DatePicker id="date-validity"
            locale="cs"
            dateFormat="dd. MM. yyyy"
            selected={value}
            filterDate={isWeekday}
            minDate={minDate}
            maxDate={maxDate}
            onChange={changeDate}
            excludeDates={holidays.map(parseISO)}
            customInput={<MyInput />}
        />
    );

}

function year_emission_validFromDate(country, emissions) {
    try{
	const today = format_iso_date(new Date());
	return emissions
	    .filter((em) => (em.country === country) && (em.available <= today))
	    .sort((a,b) => b.year < a.year ? -1 : 1)[0].validfrom;
    }catch(ex){
	return "1. 1. 2023"; // FIXME?
    }
}

export function ValidFromForm({ setValidFromDate, holidays, validity, onlyUntil, validFromDate, country, lists }) {
    // console.log('validfromform holidays ' + JSON.stringify(holidays));
    // console.log('VALIDITY in form: ' + validity);
    var maxDate = getMaxDate();
    if (onlyUntil) {
        var newMaxDate = new Date();
        newMaxDate.setDate(newMaxDate.getDate() + onlyUntil);
        maxDate = newMaxDate;
    }
    //if (country === 'cz') {
    //maxDate = new Date(2024,1,27);
    //}

    //console.log(validity);
    //console.log(onlyUntil);

    const d = new Date();
    const ddx = "1. 1. " + d.getFullYear();
    const dd = year_emission_validFromDate(country, lists.emissions);

    const MyInput2 = React.forwardRef(
        ({ value, onClick }, ref) => (
            <InputGroup ref={ref} >
                <Form.Control readOnly disabled size="sm" value={dd} />
                <InputGroup.Append>
                    <InputGroup.Text><FaCalendarAlt /></InputGroup.Text>
                </InputGroup.Append>
            </InputGroup>
        ),
    );

    const minDate = getMinDate(true, validity == 17);

    return (
        <Col xs='12' sm='6' md='3'>
            <Form.Group controlId="valid-from">
                <Form.Label >Platnost od</Form.Label>
                {validity == 1 ?
                    <DatePicker id="date-dis" customInput={<MyInput2 />}
                    />
                    :
                    <DatePickerCustom onChange={setValidFromDate}
                        weekends={true}
                        value={validFromDate}
                        minDate={minDate}
                        maxDate={maxDate}
                        holidays={[]} />
                }
            </Form.Group>
        </Col>
    );
}

export function DeliveryDate({ setDeliveryDate, holidays, deliveryDate, setReturnDate, returnDate, country }) {

    function changeTheDate(newDate) {
        setDeliveryDate(newDate);
        var newDateTomorrow = new Date(newDate);
        newDateTomorrow.setDate(newDateTomorrow.getDate() + 1);
        while ((newDateTomorrow.getDay() == 0) || (newDateTomorrow.getDay() == 6)) {
            newDateTomorrow.setDate(newDateTomorrow.getDate() + 1);
        }
        //console.log('new = ' + newDate + ' :: tomorrow = ' + newDateTomorrow + ' :: return = ' + returnDate);
        if (returnDate < newDateTomorrow) {
            //console.log('Setting returnDate');
            setReturnDate(newDateTomorrow);
        }
    }

    const longDelivery = (country === "it") || (country ===  "no-dk-swe") || (country === "fr");
    const minDate = longDelivery ? getMinDateTT() : getMinDate();
    
    return (
        <Col xs='12' sm='6' md='3'>
            <Form.Group controlId="delivery-date">
                <Form.Label>
		    Datum dodání
		    {longDelivery ?
		     <>
			 &nbsp;- <span className="text-danger">Nejdříve za 14 dní</span>
		     </> : <></>}
		</Form.Label>
                <DatePickerCustom onChange={changeTheDate} minDate={minDate} maxDate={getMaxDate()}
                    value={deliveryDate}
                    holidays={holidays} />
            </Form.Group>
        </Col>
    );
}


export function ReturnDate({ setReturnDate, holidays, deliveryDate, returnDate }) {
    // console.log('deliveryDate = ' + deliveryDate);
    var minDate = new Date();
    if (deliveryDate) {
        minDate = new Date(deliveryDate);
    }
    minDate.setDate(minDate.getDate() + 1);
    //console.log(minDate);
    //console.log('ReturnDate = ' + returnDate);
    return (
        <Col xs='12' sm='6' md='3'>
            <Form.Group controlId="return-date">
                <Form.Label>Datum vrácení</Form.Label>
                <DatePickerCustom onChange={setReturnDate} minDate={minDate} maxDate={getMaxDate()}
                    value={returnDate}
                    holidays={holidays} />
            </Form.Group>
        </Col>
    );
}


export function ValidityLengthForm({ country, onChange, validity, tollType, tunnel, getValidities, vehicle }) {

    const validities = getValidities(getProductCode(country, tollType), vehicle, tunnel);

    //console.log(validities);

    const changeValidityLength = function (ev) {
        // console.log("Answer validity: " + ev.target.value);
        if (isFunction(onChange)) {
            onChange(ev.target.value);
        }
    };

    return (
        <Col xs='12' sm='6' md='3'>
            <Form.Group controlId="toll-validity">
                <Form.Label>Délka platnosti</Form.Label>
                <Form.Control value={validity} size="sm" as="select" onChange={changeValidityLength}>
                    <RenderCountryOptions options={validities} sorting country={country} vehicle={vehicle}/>
                </Form.Control>
            </Form.Group>
        </Col>
    );
}

export function RegistrationCountry({ onChange, countries }) {

    const handleCountryChange = function (ev) {
        // console.log("Answer regCountry : " + ev.target.value);
        if (isFunction(onChange)) {
            onChange(ev.target.value);
        }
    }

    // console.log(countries);

    return (
        <Col xs='12' sm='6' md='3'>
            <Form.Group controlId="toll-country">
                <Form.Label>Stát registrace</Form.Label>
                <Form.Control defaultValue="CZ" size="sm" as="select" onChange={handleCountryChange}>
                    <option value='CZ'>Česká republika</option>
                    <option value='SK'>Slovensko</option>
                    <option value='PL'>Polsko</option>
                    <option disabled></option>
                    <RenderCountryOptions2 options={countries} />
                </Form.Control>
            </Form.Group>
        </Col>
    );
}

// Ensures all characters are from basic multilingual plane
function check_string_bmp(str) {
    const regexpBMP = /^[\u{0000}-\u{FFFF}]*$/gu;
    return regexpBMP.test(str);
}

export function validateSPZ(CurrentSPZ) {
    return ((CurrentSPZ.length >= 4) &&
        (CurrentSPZ.length <= 10) &&
        (check_string_bmp(CurrentSPZ)));
}

export function SPZ({ onChange }) { // tim onChange to posílá výše

    const [CurrentSPZ, setCurrentSPZ] = useState("");

    const handleSPZChange = function (ev) {
        var entry = ev.target.value;
        setCurrentSPZ(entry);
        if (isFunction(onChange)) {
            onChange(entry);
        }
    };

    const SPZIsValid = validateSPZ(CurrentSPZ);
    const SPZIsInvalid = !SPZIsValid;

    // OnChage={setSPZ}, komponenta zjistuje, co je SPZ, onChange definuje, co se deje po opusteni formu 

    return (
        <Col xs='12' sm='6' md='3'>
            <Form.Group controlId="SPZ">
                <Form.Label>SPZ</Form.Label>
                <Form.Control isInvalid={SPZIsInvalid} isValid={SPZIsValid} type="text" size='sm' onChange={handleSPZChange} />
                <Form.Control.Feedback type='valid'>Validní SPZ.</Form.Control.Feedback>
                <Form.Control.Feedback type='invalid'>Neplatná SPZ.</Form.Control.Feedback>
            </Form.Group>
        </Col>
    );
}

export function isFunction(functionToCheck) {
    return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
}

// <Count maxCount={50} onChange={(cnt) => console.log(cnt)} />
export function Count({ maxCount, onChange }) {    //tady si beru country a maxcount, onchange
    const [countLocal, setCountLocal] = useState(1);
    const [reason, setReason] = useState("d-none");

    const setCountSafe = function (newCount) {  //vždy na konci budu volat onChange s novym countem
        const realNewCount = parseInt(newCount);
        const finalNewCount = isNaN(realNewCount) ? 1 : realNewCount;
        if (countLocal !== finalNewCount) {
            setCountLocal(finalNewCount);
            if (isFunction(onChange)) {
                onChange(finalNewCount);
            }
        }
    }

    const handleCountChange = function (ev) {
        const newCount = parseInt(ev.target.value);
        if (newCount > maxCount) {
            setReason("text-muted");
            setCountSafe(maxCount);
        } else {
            setCountSafe(newCount);
        }
    }

    return (
        <Col xs='12' sm='6' md='3'>
            <Form.Group controlId="toll-count">
                <Form.Label>Počet</Form.Label>
                <InputGroup size='sm'>
                    <InputGroup.Prepend>
                        <Button variant="light" className="border" disabled={countLocal < 2} onClick={() => setCountSafe(countLocal - 1)} >–</Button>
                    </InputGroup.Prepend>
                    <Form.Control type="text" value={countLocal} onChange={handleCountChange} />
                    <InputGroup.Append>
                        <Button variant="light" className="border" disabled={countLocal >= maxCount} onClick={() => setCountSafe(countLocal + 1)}>+</Button>
                    </InputGroup.Append>
                </InputGroup>
                <Form.Text className={reason}>
                    Můžete objednat maximálně {maxCount}ks.
                </Form.Text>
            </Form.Group>
        </Col>
    );
}

export function Credit({ onChange, minCredit }) {

    const [localCredit, setLocalCredit] = useState(minCredit);
    const [reasonClass, setReasonClass] = useState("d-none");
    const [itsValid, setItsValid] = useState(false);
    const [itsInvalid, setItsInvalid] = useState(false);
    const [reason, setReason] = useState("");

    const changeCredit = function (ev) {
        const newCredit = parseInt(ev.target.value);
        // console.log("Answer Credit : " + ev.target.value);
        if (isNaN(newCredit)) {
            setLocalCredit('');
            setItsValid(false);
            setItsInvalid(true);
            setReasonClass("text-muted");
            setReason("Neplatný kredit!");
            onChange(0);
        } else if (newCredit < minCredit) {
            setLocalCredit(newCredit);
            setReasonClass("text-muted");
            setReason("Minimální kredit je " + minCredit + " Kč.");
            setItsValid(false);
            setItsInvalid(true);
            onChange(minCredit);
        } else {
            setReasonClass("d-none");
            setLocalCredit(newCredit);
            setItsValid(true);
            setItsInvalid(false);
            onChange(newCredit);
        }
    }

    return (
        <Col xs='12' sm='6' md='3'>
            <Form.Group controlId="toll-credit">
                <Form.Label>Kredit v Kč</Form.Label>
                <Form.Control isValid={itsValid} isInvalid={itsInvalid} size="sm" type="text" value={localCredit} onChange={changeCredit} />
                <Form.Text className={reasonClass}>
                    {reason}
                </Form.Text>
            </Form.Group>
        </Col>
    );
}

export function ReturnUnit({ onChange }) {

    const handleReturnUnitChange = function (ev) {
        // console.log("Answer ReturnMethod : " + ev.target.value);
        if (isFunction(onChange)) {
            onChange(ev.target.value);
        }
    }

    return (
        <Col xs='12' sm='6' md='3'>
            <Form.Group controlId="return-unit">
                <Form.Label>Vrácení jednotky</Form.Label>
                <Form.Control defaultValue="poštou" size="sm" as="select" onChange={handleReturnUnitChange}>
                    <option value="2">poštou</option>
                    <option value="1">osobně</option>
                </Form.Control>
            </Form.Group>
        </Col>
    );
}
