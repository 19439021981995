import React from 'react';
import Packeta from './library.js';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

const apiKey = 'c656fbd5bfc518e8';

export function Zasilkovna({setZasilkovnaStr, setZasilkovnaName, zasilkovnaName}) {
    function callback(result) {
	if (result === null) {
	    // console.log("Canceled");
	} else {
	    const keys = ["id", "name", "routingCode", "country", "city", "street"];
	    var str = "==== Zasilkovna ====\n";
	    for (var idx in keys) {
		const key = keys[idx];
		str += key + ": " + result[key] + "\n";
	    }
	    str += "========\n\n";
	    // console.log(str);
	    setZasilkovnaStr(str);
	    setZasilkovnaName(result["name"]);
	}
    }
    function pickIt() {
	// console.log("picking...");
	Packeta.Widget.pick(apiKey, callback);
    }
    const nameStr = (''+zasilkovnaName).trim().length == 0 ? "-" : zasilkovnaName;
    const nameClass = nameStr == "-" ? "text-danger" : "";
    return (
	<Row>
	  <Col md={6} className={nameClass}>
	    Vybráno: {nameStr}
	  </Col>
	  <Col md={6} className="text-right">
	    <Button onClick={pickIt} >Vybrat</Button>
	  </Col>
	</Row>
    );
}
