import React, { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { isFunction } from './form-singles';

// Ensures all characters are from basic multilingual plane
function check_string_bmp(str) {
    const regexpBMP = /^[\u{0000}-\u{FFFF}]*$/gu;
    return regexpBMP.test(str);
}

// Returns true if the string is valid email
export function validateEmail(email) {
    // eslint-disable-next-line
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email) && check_string_bmp(email);
}

export function isASCII(str) {
    return /^[\x00-\x7F]*$/.test(str);
}

export function isNumeric(s) {
    try{
	const re = /^[0-9]*$/;
	return re.test(s);
    }catch(ex) {
	return false;
    }
}


export function validatePhoneNumber(entry) {
    // console.log('validating ' + entry);
    const val = entry.replace(/ /g,"");
    // console.log('validating ' + val);
    var ok = false;

    // Valid characters
    const phonerx = /^[+]{0,1}[0-9]+$/;
    if (phonerx.test(val)) {
	//
        var prefix = val.substring(0,1);
        if (prefix === "+") {
	    if (val.length>=4) {
                var cprefix = val.substring(1,4);
                if (cprefix === "420") {
		    if ((val.length>=12)&&(val.length<=40)) {
			ok = true;
		    }
                } else {
		    if (val.length<=40) {
			ok = true;
		    }
                }
	    }
        } else {
	    if (val.length === 9) {
		ok = true;
	    }
        }
    }
    return ok;
}


export function Company({ onChange, company, optional }) {

    const changeCompany = function (ev) {
        if (isFunction(onChange)) {
            onChange(ev.target.value);
        }
    }

    const companyReal = ('' + company).trim();
    const isValid = optional ? false : !(companyReal == "");
    const isInvalid = optional ? false : !isValid;
    const reason = "Je nutné vyplnit název společnosti.";

    return (
        <Col xs="12" md="4">
            <Form.Group controlId="Firm">
                <Form.Label>Společnost</Form.Label>
                <Form.Control isValid={isValid} isInvalid={isInvalid} size="sm" type="text" value={company} onChange={changeCompany} />
		<Form.Control.Feedback type='invalid'>{reason}</Form.Control.Feedback>
            </Form.Group>
        </Col>
    );
}

export function IC({ onChange, ic }) {

    const changeIC = function (ev) {
        if (isFunction(onChange)) {
            onChange(ev.target.value);
        }
    }

    const icReal = (''+ic).trim();
    const isValid = ((icReal.length>0)&&(icReal.length<=15)&&(isNumeric(icReal)));
    const isInvalid = !isValid;
    const reason = icReal == "" ? "Je nutné vyplnit IČ." : "IČ musí být platné";
    
    return (
        <Col xs="12" md="4">
            <Form.Group controlId="IC">
                <Form.Label>IČ</Form.Label>
                <Form.Control size="sm" type="text" isValid={isValid} isInvalid={isInvalid} value={ic} onChange={changeIC} />
		    <Form.Control.Feedback type='invalid'>{reason}</Form.Control.Feedback>
            </Form.Group>
        </Col>
    );
}

export function DIC({ onChange, dic }) {

    const changeDIC = function (ev) {
        if (isFunction(onChange)) {
            onChange(ev.target.value);
        }
    }

    const dicReal = (''+dic).trim();
    const isValid = ((dicReal.length>2)&&
		     (dicReal.length<=18)&&
		     (isNumeric(dicReal.substring(2)))&&
		     (isASCII(dicReal.substring(0,2))));
    const isInvalid = !isValid;
    const reason = dicReal == "" ? "Je nutné vyplnit DIČ." : "DIČ musí být platné";
    
    return (
        <Col xs="12" md="4">
            <Form.Group controlId="DIC">
                <Form.Label>DIČ</Form.Label>
                <Form.Control isValid={isValid} isInvalid={isInvalid} size="sm" type="text" value={dic} onChange={changeDIC} />
		<Form.Control.Feedback type='invalid'>{reason}</Form.Control.Feedback>
            </Form.Group>
        </Col>
    );
}

export function Address({ onChangeStreet, onChangeCity, onChangeZip, street, city, zip, optional }) {

    const changeStreet = function (ev) {
        if (isFunction(onChangeStreet)) {
            onChangeStreet(ev.target.value);
        }
    }
    const changeCity = function (ev) {
        if (isFunction(onChangeCity)) {
            onChangeCity(ev.target.value);
        }
    }
    const changeZip = function (ev) {
        if (isFunction(onChangeZip)) {
            onChangeZip(ev.target.value);
        }
    }

    const streetReal = ('' + street).trim();
    const isStreetValid = optional ? false : (streetReal != "");
    const isStreetInvalid = optional ? false : !isStreetValid;
    const streetReason = "Je nutné vyplnit ulici a číslo domu.";
    const cityReal = ('' + city).trim();
    const isCityValid = optional ? false : (cityReal != "");
    const isCityInvalid = optional ? false : !isCityValid;
    const cityReason = "Je nutné vyplnit město.";
    const zipReal = ('' + zip).trim();
    const isZipValid = optional ? false : ((zipReal.length > 0) && (zipReal.length <=7)) && (isNumeric(zipReal));
    const isZipInvalid = optional ? false : !isZipValid;
    const zipReason = (zipReal == "") ? "Je nutné vyplnit PSČ." : "PSČ musí být ve správném formátu.";
    
    return (
        <Form.Row>
            <Col xs="12" md="4">
                <Form.Group controlId="Address">
                    <Form.Label>Ulice a číslo domu</Form.Label>
                    <Form.Control isValid={isStreetValid} isInvalid={isStreetInvalid} size="sm" type="text" onChange={changeStreet} value={street} />
		    <Form.Control.Feedback type='invalid'>{streetReason}</Form.Control.Feedback>
                </Form.Group>
            </Col>
            <Col xs="12" md="4">
                <Form.Group controlId="City">
                    <Form.Label>Město</Form.Label>
                    <Form.Control isValid={isCityValid} isInvalid={isCityInvalid} size="sm" type="text" onChange={changeCity} value={city} />
		    <Form.Control.Feedback type='invalid'>{cityReason}</Form.Control.Feedback>
                </Form.Group>
            </Col>
            <Col xs="12" md="4">
                <Form.Group controlId="Zip">
                    <Form.Label>PSČ</Form.Label>
                    <Form.Control isValid={isZipValid} isInvalid={isZipInvalid} size="sm" type="text" onChange={changeZip} value={zip} />
		    <Form.Control.Feedback type='invalid'>{zipReason}</Form.Control.Feedback>
                </Form.Group>
            </Col>
        </Form.Row>
    );
}

export function validateFullName(fullName, optional) {
    const fullNameReal = (''+fullName).trim();
    const fullNameAry = fullNameReal.split(" ");
    const isValid = optional && (fullNameReal == "") ? false : fullNameAry.length >= 2 ;
    const isInvalid = optional && (fullNameReal == "") ? false : !isValid ;
    const reason = (fullNameReal == "") ? "Je nutné vyplnit jméno a příjmení." : "Jméno i příjmení musí být vyplněno.";
    return [isValid, isInvalid, reason];
}

export function FullName({ onChange, fullName, optional }) {

    const changeName = function (ev) {
        if (isFunction(onChange)) {
            onChange(ev.target.value);
        }
    }

    const [ isValid, isInvalid, reason ] = validateFullName(fullName, optional);

    return (
        <Col xs="12" md="4">
            <Form.Group controlId="Name">
                <Form.Label>Jméno a příjmení</Form.Label>
                <Form.Control isValid={isValid} isInvalid={isInvalid} size="sm" type="text" value={fullName} onChange={changeName} />
		<Form.Control.Feedback type='invalid'>{reason}</Form.Control.Feedback>
            </Form.Group>
        </Col>
    );
}

export function validatePhone(phone, required) { // required means optional here (sigh)
    const phoneIsValid = required ? false : validatePhoneNumber(phone);
    const phoneIsInvalid = required ? false : !phoneIsValid;
    const reason = (phone == "") ? "Je nutné vyplnit telefon!" : "Neplatný telefon.";
    return [ phoneIsValid, phoneIsInvalid, reason ];
}

export function Phone({ onChange, phone, required }) { // required means optional ...


    const changePhone = function (ev) {
        var entry = ev.target.value;
        if (isFunction(onChange)) {
            onChange(entry);
        }
    }

    const [ phoneIsValid, phoneIsInvalid, reason ] = validatePhone(phone, required);
 
    return (
        <Form.Group controlId="phone">
            <Form.Label>Telefon</Form.Label>
            <Form.Control size="sm" type="text" value={phone} placeholder="+420123456789" isInvalid={phoneIsInvalid} isValid={phoneIsValid} onChange={changePhone} />
            <Form.Control.Feedback type='invalid'>{reason}</Form.Control.Feedback>
        </Form.Group>
    );
}

export function Mail({ onChange, mail }) {

    const changeMail = function (ev) {
        var entry = ev.target.value;
        if (isFunction(onChange)) {
            onChange(entry);
        }
    }

    const emailIsValid = validateEmail(mail);
    const emailIsInvalid = !emailIsValid;

    const reason = (mail == "") ? "Je nutné vyplnit e-mail!" : "Neplatný e-mail.";

    return (
        <Form.Group controlId="email">
            <Form.Label>Email</Form.Label>
            <Form.Control size="sm" type="email" placeholder="jmeno@email.cz" isInvalid={emailIsInvalid} isValid={emailIsValid} onChange={changeMail} value={mail} />
            <Form.Control.Feedback type='invalid'>{reason}</Form.Control.Feedback>
        </Form.Group>
    );
}
