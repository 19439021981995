import React, { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import { validatePhoneNumber, Company, IC, DIC, Mail, Phone, Address, FullName, validateFullName, validatePhone, validateEmail, isNumeric, isASCII } from './contact-form-singles';
import { isFunction, validateSPZ } from './form-singles';
import Button from 'react-bootstrap/Button';
import { Zasilkovna } from './zasilkovna';

function Branches({ branches, placesEnabled, branch, setBranch }) {
    function onChange(ev) {
        setBranch(ev.target.value);
    }
    return (
        <Form.Group controlId="osobne-misto">
            <Form.Control size="sm" as="select" disabled={!placesEnabled} value={branch} onChange={onChange}>
                {branches ? branches.map((br) => <option key={br[0]} value={br[0]}>{br[1]}</option>) : ""}
            </Form.Control>
        </Form.Group>
    );
}

export const paymentRadios = [
    {
        number: 1,
        label: "Hotově"
    },
    {
        number: 2,
        label: "Bankovním převodem nebo vkladem na účet"
    },
    {
        number: 3,
        label: "Kartou online předem"
    },
    {
        number: 4,
        label: "Na dobírku (60 Kč)" //zůstává
    }
];

export function DeliverPay({
    enabledDeliveryMethods, deliveryMethod, setDeliveryMethod, enabledPaymentMethods,
    paymentMethod, setPaymentMethod, branches, branch, setBranch, setZasilkovnaStr, setZasilkovnaName, zasilkovnaName
}) {
    //console.log(enabledDeliveryMethods);
    const deliveryRadios = [
        {
            number: 1,
            label: "Osobní odběr (60 Kč)", // zůstává
            addBranches: true,
            addZasilkovna: false
        },
        {
            number: 3,
            label: "Kurýrem (140 Kč)",  //změna 130 -> 140
            addZasilkovna: false
        },
        {
            number: 2,
            label: "Poštou (90 Kč)", // změna 80 -> 90
            addZasilkovna: false
        },
        {
            number: 4,
            label: "Zásilkovna (90 Kč)", //změna 80 -> 90
            addZasilkovna: true
        }
    ];
    //console.log(enabledDeliveryMethods);
    const placesEnabled = enabledDeliveryMethods[1] && deliveryMethod === 1;
    const onlineDelivery = Object.values(enabledDeliveryMethods).filter((x) => x === true).length === 0;
    const notChoosenDelivery = onlineDelivery ? "" : "Vyberte způsob dopravy";
    return (
        <>
            <Card body className="mb-2">
                <Row>
                    <Col xs="12" sm="6">
                        <h5 className='mb-3'>Doprava</h5>
                        {deliveryRadios.map(function (dr) {
                            const enabled = enabledDeliveryMethods[dr.number];
                            function onChange() {
                                setDeliveryMethod(dr.number);
                            }
                            const checked = enabled && (deliveryMethod === dr.number);
                            return (
                                <React.Fragment key={dr.number}>
                                    <Form.Group key={dr.number} controlId={'delivery' + dr.number}>
                                        <Form.Check disabled={!enabled} name="delivery" type="radio" value={dr.number}
                                            label={dr.label} onChange={onChange} checked={checked} />
                                        {dr.addZasilkovna && enabledDeliveryMethods[4] && deliveryMethod == 4 ? <Zasilkovna setZasilkovnaName={setZasilkovnaName} setZasilkovnaStr={setZasilkovnaStr} zasilkovnaName={zasilkovnaName} /> : ""}
                                    </Form.Group>
                                    {dr.addBranches ? <Branches branches={branches} placesEnabled={placesEnabled} branch={branch} setBranch={setBranch} /> : ""}
                                </React.Fragment>
                            );
                        })}
                        {deliveryMethod === null ? <span className='text-danger'>{notChoosenDelivery}</span> : ""}
                        <hr className='d-block d-sm-none' />
                    </Col>
                    <Col xs="12" sm="6">
                        <h5 className='mb-3'>Platba</h5>
                        {paymentRadios.map(function (pm) {
                            var enabled = enabledPaymentMethods[pm.number];
                            if (pm.number == 4) {
                                if ((!enabledDeliveryMethods[2]) && (!enabledDeliveryMethods[3]) && (!enabledDeliveryMethods[4])) {
                                    enabled = false;
                                }
                                if ((deliveryMethod != 2) && (deliveryMethod != 3) && (deliveryMethod != 4)) {
                                    enabled = false;
                                }
                            }
                            function onChange() {
                                setPaymentMethod(pm.number);
                            }
                            const checked = enabled && (paymentMethod === pm.number);
                            return (
                                <Form.Group key={pm.number} controlId={'pm' + pm.number}>
                                    <Form.Check name="payment" type="radio" label={pm.label}
                                        disabled={!enabled} onChange={onChange}
                                        checked={checked} />
                                </Form.Group>
                            );
                        })}
                    </Col>
                </Row>
            </Card>
        </>
    );
}

export function validateAccount(acc) {
    if (acc.length > 0) {
        // console.log('acc = ' + acc);
        var accs = acc.split("/");
        // console.log('accs = ' + JSON.stringify(accs));
        if (accs.length != 2) {
            return false;
        } else {
            const accountno = accs[0];
            const bankcode = accs[1];
            // console.log('accountno = ' + accountno);
            // console.log('bankcode = ' + bankcode);
            const ans = accountno.split("-");
            const an1 = ('' + ans[0]).trim();
            const an2 = ('' + ans[1]).trim();
            // console.log(ans);
            // console.log(an2);
            // console.log(Number.isInteger(parseInt(an2)));
            if ((accountno.length > 0) &&
                (accountno.length <= 34) &&
                (bankcode.length > 0) &&
                (bankcode.length <= 11) &&
                (Number.isInteger(parseInt(an1))) &&
                ((ans.length == 1) || (Number.isInteger(parseInt(an2)))) &&
                (Number.isInteger(parseInt(bankcode))) &&
                //(accountno == parseInt(accountno))&&
                (bankcode == parseInt(bankcode))) {
                return true;
            } else {
                return false;
            }
        }
    }
    return false;
}

export function Overpaid({ setOverpay, setAccount, account }) {

    function textChanged(ev) {
        setAccount(ev.target.value);
        setOverpay(1); // Constant, effectively
    }

    const accountReal = ("" + account).trim();
    const isValid = validateAccount(accountReal);
    const isInvalid = !isValid;
    const reason = accountReal == "" ? "Je nutné vyplnit číslo účtu." : "Neplatné číslo účtu.";

    return (
        <Card body className="mb-2">
            <h5 className='mb-3'>Vrácení přeplatku mýtného</h5>
            <Row>
                <Col md={6}>
                    <Form.Group controlId="vratky3" as={Row} className="ml-1 mr-1">
                        <Form.Label>Na účet číslo:</Form.Label>
                        <Col>
                            <Form.Control isInvalid={isInvalid} isValid={isValid} size="sm" type="text" onChange={textChanged} />
                            <Form.Control.Feedback type='invalid'>{reason}</Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                </Col>
            </Row>
        </Card>
    );
}

export function InvoiceDelivery({ onChange, setCompany, setIC, setDIC, setStreet, setCity, setZip, setFullName, setPhone, setMail, setFullName2, setCompany2, setStreet2, setCity2, setZip2, onChangePhone, company, company2, fullName, fullName2, ic, dic, phone, phone2, street, city, zip, street2, city2, zip2, mail, note, firma, setFirma }) {

    const [deliverInfo, setDeliverInfo] = useState(false);
    //const [PhoneIsValid, setPhoneIsValid] = useState(false);
    //const [PhoneIsInvalid, setPhoneIsInvalid] = useState(false);

    const changeFirma = function (ev) {
        if (firma == false) {
            setFirma(true);
        } else {
            setFirma(false);
        }
    }

    const changeDeliverInfo = function (ev) {
        if (deliverInfo == false) {
            setDeliverInfo(true);
        } else {
            setDeliverInfo(false);
        }
    }

    const changeNote = function (ev) {
        if (isFunction(onChange)) {
            onChange(ev.target.value);
        }
    }

    /*
    const validatePhone = function (entry) {
        const ok = validatePhoneNumber(entry);
        setPhoneIsValid(ok);
        setPhoneIsInvalid(!ok);
    console.log('result ' + ok);
        setPhone(entry);
    }/**/

    const changePhone2 = function (ev) {
        var entry = ev.target.value;
        // console.log('changePhone2 ' + entry);
        if (isFunction(onChangePhone)) {
            // console.log('function');
            onChangePhone(entry);
        }
    }

    const phoneIsValid = (phone2 == '') ? false : validatePhoneNumber(phone2);
    const phoneIsInvalid = (phone2 == '') ? false : !phoneIsValid;

    return (
        <Card body className="mb-2">
            <h5>Fakturační a dodací údaje</h5>
            <Form.Group controlId="firma">
                <Form.Check type="checkbox" label="Nakupuji na firmu" onClick={changeFirma} />
            </Form.Group>

            {firma == false ?
                <Form.Row>
                    <FullName onChange={setFullName} fullName={fullName} />
                    <Col xs="12" md="4">
                        <Phone onChange={setPhone} phone={phone} />
                    </Col>
                    <Col xs="12" md="4">
                        <Mail onChange={setMail} mail={mail} />
                    </Col>
                </Form.Row>
                :
                <Form.Row>
                    <Company onChange={setCompany} company={company} />
                    <IC onChange={setIC} ic={ic} />
                    <DIC onChange={setDIC} dic={dic} />
                </Form.Row>
            }

            <Address onChangeStreet={setStreet} street={street} city={city} zip={zip} onChangeCity={setCity} onChangeZip={setZip} />

            {firma == false ? "" :
                <Form.Row>
                    <FullName onChange={setFullName} fullName={fullName} />
                    <Col xs="12" md="4">
                        <Phone onChange={setPhone} phone={phone} />
                    </Col>
                    <Col xs="12" md="4">
                        <Mail onChange={setMail} mail={mail} />
                    </Col>
                </Form.Row>
            }

            <Form.Group controlId="doruceni_jine">
                <Form.Check type="checkbox" label="Vyplnit jiné doručovací údaje" onClick={changeDeliverInfo} />
            </Form.Group>

            {deliverInfo == false ? "" :
                <>
                    <Form.Row>
                        <FullName onChange={setFullName2} fullName={fullName2} optional={true} />
                        <Col xs="12" md="4">
                            <Form.Group controlId="phone21">
                                <Form.Label>Telefon pro dopravce</Form.Label>
                                <Form.Control size="sm" type="text" placeholder="+420123456789" isInvalid={phoneIsInvalid} isValid={phoneIsValid} onChange={changePhone2} value={phone2} />
                            </Form.Group>
                        </Col>
                        <Company onChange={setCompany2} company={company2} optional={true} />
                    </Form.Row>
                    <Address onChangeStreet={setStreet2} onChangeCity={setCity2} onChangeZip={setZip2} street={street2} city={city2} zip={zip2} optional={true} />
                </>
            }
            <Form.Group controlId="note">
                <Form.Label>Poznámka</Form.Label>
                <Form.Control size="sm" as="textarea" onChange={changeNote} value={note} />
            </Form.Group>
        </Card>

    );
}

const product_needs_spz = {
    'cze': true,
    'oste': true,
    'ostet': true,
    'sk': true,
    'hu': true,
    'it': true,
    'fr': true,
    'no-dk-swe': true
};

export function BasketCanSend(
    {
        placeOrder, checkRead, checkAgree, items,
        fullName, phone, mail, street, city, zip,
        firma, company, ic, dic,
        itemsWithUnits, account,
        deliveryMethod, enabledDM, zasilkovnaStr
    }) {
    const hasItems = items.filter((item) => item && item.data && item.data.country && item.data.country != "--").length > 0;
    const [fullNameValid] = validateFullName(fullName);
    const [phoneValid] = validatePhone(phone, false);
    const mailValid = validateEmail(mail);
    const streetValid = ("" + street).trim() != "";
    const cityValid = ("" + city).trim() != "";
    const zipReal = ("" + zip).trim();
    const zipValid = ((zipReal.length > 0) && (zipReal.length <= 7)) && (isNumeric(zipReal));
    const companyValid = ("" + company).trim() != "";
    const icReal = ('' + ic).trim();
    const icValid = ((icReal.length > 0) && (icReal.length <= 15) && (isNumeric(icReal)));
    const dicReal = ('' + dic).trim();
    const dicValid = ((dicReal.length > 2) &&
        (dicReal.length <= 18) &&
        (isNumeric(dicReal.substring(2))) &&
        (isASCII(dicReal.substring(0, 2))));
    const firmaValid = firma ? companyValid && icValid && dicValid : true;
    const accountReal = ("" + account).trim();
    const accountRealValid = validateAccount(accountReal);
    const accountValid = itemsWithUnits ? accountRealValid : true;

    const itemsWithBadSPZs = items
        .filter((item) => item.data && item.data.productCode && product_needs_spz[item.data.productCode])
        .filter((item) => !(item.data.spz && validateSPZ(item.data.spz)));
    const itemsSPZValid = itemsWithBadSPZs.length == 0;

    const noDeliveryMethods = !(enabledDM[1] || enabledDM[2] || enabledDM[3] || enabledDM[4]);
    const deliveryOK = noDeliveryMethods ? true : deliveryMethod ? enabledDM[deliveryMethod] : false;
    // console.log(enabledDM);
    // console.log(noDeliveryMethods);
    // console.log(deliveryOK);

    const zasilkovnaOK = noDeliveryMethods ? true : deliveryMethod == 4 ? zasilkovnaStr != '' : true;

    const canOrder = checkRead && checkAgree &&
        hasItems &&
        fullNameValid && phoneValid && mailValid &&
        streetValid && cityValid && zipValid &&
        firmaValid && accountValid && itemsSPZValid &&
        deliveryOK && zasilkovnaOK;

    return (
        <>
            {canOrder ? "" :
                <Card className="mb-3" border="danger" text="danger" style={{ border: "1px solid red ! important" }}>
                    <Card.Body>
                        <Card.Text>
                            Vyplňte, prosím, všechny požadované údaje, opravte případné chyby a potvrďte prosím přečtení obchodních podmínek a informace o registraci v mýtných systémech.
                        </Card.Text>
                    </Card.Body>
                </Card>
            }
            <Button onClick={placeOrder} size="lg" className="float-right" variant="primary" disabled={!canOrder} >Objednat</Button>
        </>
    );
}

