import React, { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import { VehicleTypeForm, ValidFromForm, ValidityLengthForm, RegistrationCountry, SPZ, Count, Credit, DeliveryDate, ReturnDate, ReturnUnit, isFunction } from './form-singles';
import { SinglePrice, CompoundPrice } from './pricing';
import { RenderCountryOptions } from './country-card';


export const tunnels = [
	["1", "A 13 dálnice Brenner"],
	["2", "A 9 Pyhrn / Bosrucktunnel"],
	["3", "A 9 Pyhrn / Gleinalmtunnel"],
	["4", "A 9 Pyhrn / Bosruck a Gleinalmtunnel"],
	["5", "A 10 dálnice Tauern"],
	["6", "A 11 Karawanken směr jih"],
	["7", "S 16 silniční tunel Arlberg"]];

// to mi chybí dodělat
function getMaxCount({ country, validity, vehicle }) {
	switch (country) {
		case 'cz':
			switch (validity) {
				case '2':
					return 200;
			}
		case 'ost':
			switch (vehicle) {
				case '8':
					return 3517;
			}
		case 'ch':
			return 490;
		case 'slo':
			switch (validity) {
				case '2':
					return 200;
			}
		case 'hr':
			return 10;

	}
	return 10;
}

/*product form for all countries switch function, we have 4 types:
1 ProductFormEtoll - pro všechny e-známky
2 ProductFormVignette - pro kupony a vouchery (voucher má platnost a počet)
3 ProductFormUnit - pro jednotky
4 MassShopping - hromadný nákup
*/

export function ProductForm({ country, tolltype, setValidity, validity, setRegCountry, setVehicle, vehicle, setReturnMethod,
	setSPZ, setTunnel, setCredit, credit, setCount, count, setStartDate, setValidFromDate, setDeliveryDate, deliveryDate,
	setReturnDate, returnDate, setSPZcrowd, holidays, lists, onlyUntil, tunnel, getValidities, validFromDate,
	inscription, setInscription }) {

	/*
	console.log(tolltype);
	console.log(tunnel);
	console.log(vehicle);
	*/



	if ((country === 'cz' && tolltype === 'etoll') ||
		(country === 'ost' && tolltype === 'etoll') ||
		(country === 'ost' && tolltype === 'tunnel') ||
		(country === 'sk') || (country === 'hu') || (country === 'slon') ||
		(country === 'ch' && tolltype === 'etoll')
	) {
		return (
			<ProductFormEtoll
				lists={lists}
				tunnel={tunnel}
				getValidities={getValidities}
				onlyUntil={onlyUntil}
				holidays={holidays}
				tolltype={tolltype}
				country={country}
				setValidity={setValidity}
				validity={validity}
				setRegCountry={setRegCountry}
				setVehicle={setVehicle}
				vehicle={vehicle}
				setSPZ={setSPZ}
				onChange={setTunnel}
				setStartDate={setStartDate}
				setValidFromDate={setValidFromDate} validFromDate={validFromDate}
				count={count} />
		);
	} else if
		((country === 'cz' && tolltype === 'voucher') ||
		(country === 'ost' && tolltype === 'vignette') ||
		(country === 'ch' && tolltype === 'vignette') ||
		(country === 'slo')
	) {
		return (
			<ProductFormVignette
				lists={lists}
				tunnel={tunnel}
				getValidities={getValidities}
				tolltype={tolltype}
				holidays={holidays}
				country={country}
				setValidity={setValidity}
				validity={validity}
				setRegCountry={setRegCountry}
				setVehicle={setVehicle}
				vehicle={vehicle}
				setCount={setCount}
				count={count}
				setStartDate={setStartDate}
				setValidFromDate={setValidFromDate} validFromDate={validFromDate}
			/>
		);
	} else if
		(country === 'hr' || country === "it" || country === "fr" || country === "no-dk-swe") {
		return (
			<ProductFormUnit
				lists={lists}
				holidays={holidays}
				tunnel={tunnel}
				getValidities={getValidities}
				tolltype={tolltype}
				country={country}
				setRegCountry={setRegCountry}
				setVehicle={setVehicle}
				vehicle={vehicle}
				setReturnMethod={setReturnMethod}
				setSPZ={setSPZ}
				setCredit={setCredit}
				credit={credit}
				setCount={setCount}
				count={count}
				setStartDate={setStartDate}
				setDeliveryDate={setDeliveryDate}
				deliveryDate={deliveryDate}
				setReturnDate={setReturnDate}
				returnDate={returnDate}
			/>
		);
	} else if
		(country === 'cz' && tolltype === 'mass') {
		return (
			<MassShopping
				vehicle={vehicle}
				lists={lists}
				holidays={holidays}
				tunnel={tunnel}
				onlyUntil={onlyUntil}
				getValidities={getValidities}
				tolltype={tolltype}
				country={country}
				setValidity={setValidity}
				validity={validity}
				setRegCountry={setRegCountry}
				setStartDate={setStartDate}
				setValidFromDate={setValidFromDate} validFromDate={validFromDate}
				onChange={setSPZcrowd}
				count={count} />
		);
	} else if (country === "gv") {
		return (
			<GenericVoucher
				country={country}
				setCount={setCount}
				count={count}
				getValidities={getValidities}
				tunnel={tunnel}   //null value is usefull for function getValidities
				vehicle={vehicle} //null value is usefull for function getValidities and also SinglePrice component
				setValidity={setValidity}
				validity={validity}
				tolltype={tolltype} //this is needed for SinglePrice component
				lists={lists}
				inscription={inscription}
				setInscription={setInscription}
			/>
		);
	}
	else {
		return (
			<p>Formulář nenalezen.</p>
		);
	}
}

function RenderTunnelOptions2({ options }) {
	// console.log("========================================");
	//  console.log("objectkeys = " + Object.keys(options));
	return (
		<>
			{
				options.map(function (cntr) {
					// console.log(cntr + ' => ' + options[cntr]);
					return (
						<option value={cntr.id} key={cntr.id}>
							{cntr.description}
						</option>
					);
				})
			}
		</>
	);
}

/*dílčí komponenty pro ProductForm výše, mají v sobě zobrazení ceny zabudované*/

export function ProductFormEtoll({ tolltype, country, setValidity, validity, setRegCountry, setVehicle, vehicle, setSPZ, onChange, setStartDate, setValidFromDate, holidays, count, lists, tunnels, onlyUntil, tunnel, getValidities, validFromDate }) {

	// console.log(lists.tunnels);
	//	console.log(Object.keys(lists.tunnels));
	//	console.log(Object.keys(lists.worldCountries));

	//console.log(country);

	const changeTunnel = function (ev) {
		//console.log("Answer tunnel : " + ev.target.value);
		if (isFunction(onChange)) {
			onChange(ev.target.value);
		}
	}

	return (
		<>
			{country === 'cz' ? ""
				:
				<Row><VehicleTypeForm country={country} onChange={setVehicle} vehiclesTypes={lists.typeSets} vehicle={vehicle} tollType={tolltype} /></Row>
			}
			<Row>
				{tolltype != 'tunnel' ?
					<>
						<ValidityLengthForm country={country} onChange={setValidity} validity={validity}
							vehicle={vehicle}
							tollType={tolltype} tunnel={tunnel} getValidities={getValidities} />
						<ValidFromForm setValidFromDate={setValidFromDate} validFromDate={validFromDate} holidays={holidays} validity={validity} onlyUntil={onlyUntil}
							country={country} lists={lists} />
					</>
					:
					<>
						<Col xs='12' sm='6' md='3'>
							<Form.Group controlId="tunnel-type">
								<Form.Label>Úsek</Form.Label>
								<Form.Control size="sm" as="select" defaultValue='--' onChange={changeTunnel}>
									<RenderTunnelOptions2 options={lists.tunnels} />
								</Form.Control>
							</Form.Group>
						</Col>
						<ValidityLengthForm vehicle={vehicle} country={country} onChange={setValidity} validity={validity}
							tollType={tolltype} tunnel={tunnel} getValidities={getValidities} />
					</>
				}
				<RegistrationCountry onChange={setRegCountry} countries={lists.worldCountries} />
				<SPZ onChange={setSPZ} />
			</Row>
			{country === 'cz' ?
				<CompoundPrice country={country} tolltype={tolltype} count={count} vehicle={vehicle} validity={validity} lists={lists} />
				:
				<SinglePrice country={country} validity={validity} count={count} vehicle={vehicle} lists={lists} tolltype={tolltype} tunnel={tunnel} />
			}
		</>
	);
}

export function ProductFormVignette({ country, setValidity, validity, setVehicle, vehicle, setCount, count, setStartDate, setValidFromDate, holidays, tunnel, getValidities, tolltype, validFromDate, lists }) {
	// + czech voucher
	switch (country) {
		case 'ch':
			return (
				<Row>
					<Count maxCount={490} onChange={setCount} />
					<Col className="d-none d-md-block"></Col>
					<Col className="d-none d-md-block"></Col>
					<SinglePrice country={country} count={count} lists={lists} tolltype={tolltype} tunnel={tunnel} />
				</Row>
			);
		case 'ost':
			return (
				<>
					<Row>
						<VehicleTypeForm country={country} onChange={setVehicle} vehiclesTypes={lists.typeSets} vehicle={vehicle} />
						<ValidityLengthForm vehicle={vehicle} country={country} onChange={setValidity} validity={validity}
							tollType={tolltype} tunnel={tunnel} getValidities={getValidities}
							lists={lists} />
						<ValidFromForm setValidFromDate={setValidFromDate} validFromDate={validFromDate} holidays={holidays} validity={validity}
							lists={lists} country={country} />
						<Count maxCount={212} onChange={setCount} />
					</Row>
					<SinglePrice country={country} validity={validity} count={count} vehicle={vehicle} lists={lists} tolltype={tolltype} tunnel={tunnel} />
				</>
			);
		case 'slo':
			return (
				<Row>
					<ValidityLengthForm vehicle={vehicle} country={country} onChange={setValidity} validity={validity}
						tollType={tolltype} tunnel={tunnel} getValidities={getValidities} />
					<ValidFromForm setValidFromDate={setValidFromDate} validFromDate={validFromDate} holidays={holidays}
						lists={lists} country={country} />
					<Count maxCount={1000} onChange={setCount} />
					<SinglePrice country={country} count={count} validity={validity} lists={lists} tolltype={tolltype} tunnel={tunnel} />
				</Row>
			);
		case 'cz':
			return (
				<Row>
					<ValidityLengthForm vehicle={vehicle} country={country} onChange={setValidity} validity={validity}
						tollType={tolltype} tunnel={tunnel} getValidities={getValidities} />
					<Count maxCount={340} onChange={setCount} />
					<Col></Col>
					<SinglePrice country={country} count={count} validity={validity} lists={lists} tolltype={tolltype} tunnel={tunnel} />
				</Row>
			);
		default:
			return (
				<p>Formulář nenalezen.</p>
			);
	}
}

export function ProductFormUnit({ country, setRegCountry, setVehicle, vehicle, setReturnMethod, setSPZ, setCredit, credit, setCount, count, setDeliveryDate, deliveryDate, setReturnDate, returnDate, holidays, lists }) {

	const minCredit = (country == 'hr') ? 2500 : 3000;
	switch (country) {
		case 'it':
		case 'no-dk-swe':
			return (
				<>
					<Row>
						<RegistrationCountry onChange={setRegCountry} countries={lists.worldCountries} />
						<SPZ onChange={setSPZ} />
						<DeliveryDate returnDate={returnDate} setReturnDate={setReturnDate} setDeliveryDate={setDeliveryDate} deliveryDate={deliveryDate} holidays={holidays} country={country} />
						<ReturnDate setReturnDate={setReturnDate} deliveryDate={deliveryDate} returnDate={returnDate} holidays={holidays} />
					</Row>
					<Row>
						<Credit onChange={setCredit} minCredit={minCredit} />
						<ReturnUnit onChange={setReturnMethod} />
						<Col></Col>
						<CompoundPrice country={country} count={count} credit={credit} deliveryDate={deliveryDate} returnDate={returnDate} lists={lists} />
					</Row>
				</>
			);
		case 'fr':
		case 'hr':
			return (
				<>
					<Row>
						<VehicleTypeForm country={country} onChange={setVehicle} vehiclesTypes={lists.typeSets} vehicle={vehicle} />
						<Credit onChange={setCredit} minCredit={minCredit} />
						<DeliveryDate returnDate={returnDate} setReturnDate={setReturnDate} setDeliveryDate={setDeliveryDate} deliveryDate={deliveryDate} holidays={holidays} country={country} />
						<ReturnDate setReturnDate={setReturnDate} deliveryDate={deliveryDate} returnDate={returnDate} holidays={holidays} />
					</Row>
					<Row>
						<RegistrationCountry onChange={setRegCountry} countries={lists.worldCountries} />
						{country != 'hr' ?
							<SPZ onChange={setSPZ} /> : <Count maxCount={10} onChange={setCount} />
						}
						<ReturnUnit onChange={setReturnMethod} />
						<CompoundPrice country={country} count={count} credit={credit} deliveryDate={deliveryDate} returnDate={returnDate} vehicle={vehicle} lists={lists} />
					</Row>
				</>
			);
	}
}

export function MassShopping({ country, tolltype, setValidity, validity, setRegCountry, setStartDate, setValidFromDate, onChange, holidays, count, lists, tunnel, getValidities, vehicle, validFromDate, onlyUntil }) {

	const [SPZnum, setSPZnum] = useState(0);

	const changeSPZcrowd = function (ev) {
		var entry = ev.target.value;
		var spzs = entry.match(/[a-z0-9]+/ig);
		if (spzs == null) {
			spzs = [];
		}
		var spzsCount = spzs ? spzs.length : 0;
		setSPZnum(spzsCount);
		//console.log(spzs);
		//console.log("už jich máš:" + spzsCount);
		if (isFunction(onChange)) {
			onChange(entry);
		}
	}

	return (
		<>
			<Row>
				<ValidityLengthForm vehicle={vehicle} country={country} onChange={setValidity} validity={validity}
					tollType={tolltype} tunnel={tunnel} getValidities={getValidities} />
				<ValidFromForm setValidFromDate={setValidFromDate} validFromDate={validFromDate} holidays={holidays}
					country={country} lists={lists} onlyUntil={onlyUntil}					/>
				<RegistrationCountry onChange={setRegCountry} countries={lists.worldCountries} />
			</Row>
			<Row>
				<Col xs='12' sm='6' md='6' xl='9'>
					<Form.Group controlId="toll-spz67">
						<Form.Label>SPZ (zadáno {SPZnum} SPZ)</Form.Label>
						<Form.Control as="textarea" onChange={changeSPZcrowd} />
						<Form.Text className="text-muted">
							SPZ zadejte bez mezer a bez pomlček, jednotlivé SPZ oddělte čárkou, mezerou nebo novým řádkem.
						</Form.Text>
					</Form.Group>
				</Col>
				<Col xs='12' sm='6' md='6' xl='3' >
					<CompoundPrice country={country} tolltype={tolltype} count={count} validity={validity} SPZnum={SPZnum} lists={lists} />
				</Col>
			</Row>
		</>
	);
}

export function GenericVoucher({ country, setCount, count, tolltype,
	vehicle, setValidity, validity, tunnel, getValidities, lists,
	inscription, setInscription }) {

	const maxCount = 500; //maximum amount of vouchers per one order

	/*
	console.log(tunnel);
	console.log(vehicle);
	console.log(tolltype);
	console.log(country);
	
	console.log(validity);
	console.log(count);
	console.log(setCount);
    console.log(inscription);
	*/


    const validities = getValidities(country, vehicle, tunnel); //this works well when vehicle and tunnel are null here
    // const validities = [...validities0].reverse();

	const changeValidityLength = function (ev) {
		// console.log("Answer validity: " + ev.target.value);
		if (isFunction(setValidity)) {
			setValidity(ev.target.value);
		}
	};

	const changeInscription = function (ev) {
		// console.log("Answer validity: " + ev.target.value);
		const trimmed_inscription = ev.target.value.slice(0, 75);
		if (isFunction(setInscription)) {
			setInscription(trimmed_inscription);
		}
	};

	const invalidInscription = inscription.length >= 75;
	const feedback = invalidInscription ? "Dosáhli jste maximální délky 75 znaků." : "";
	//console.log(inscription.length);

	return (
		<>
			<Row>
				<Col xs='12' sm='6' md='3'>
					<Form.Group controlId="voucher-validity">
						<Form.Label>Hodnota voucheru</Form.Label>
						<Form.Control value={validity} size="sm" as="select" onChange={changeValidityLength}>
							<RenderCountryOptions options={validities} sorting country={country} vehicle={vehicle} />
						</Form.Control>
					</Form.Group>

				</Col>
				<Count maxCount={maxCount} onChange={setCount} />
				<Col></Col>


			</Row>
			<Row>
				<Col xs='12' sm='9'>
					<Form.Group controlId="inscription" className='mb-3'>
						<Form.Label>Věnování (volitelné)</Form.Label>
						<Form.Control type="text" isInvalid={invalidInscription}
							value={inscription} onChange={changeInscription} />
						<Form.Control.Feedback type='invalid'>{feedback}</Form.Control.Feedback>
						<small className='text-muted'>Maximální délka je 75 znaků.</small>
					</Form.Group>
				</Col>


				<SinglePrice country={country} count={count} validity={validity} lists={lists} tolltype={tolltype} vehicle={vehicle} tunnel={tunnel} />


			</Row>
		</>
	)
}
